span.tag {
  padding: 0.3125rem 0.625rem;
  border-radius: 0.25rem;
  border: 0.0625rem solid black;
  font-weight: 600;
  font-size: clamp(0.6rem, 2.5vw, 0.8rem);
}

@media screen and (orientation: portrait) {
  span.tag {
    padding: 0.3125rem;
  }
}
