a.next-post,
a.previous-post {
  line-height: 1.2;
  font-size: clamp(0.8rem, 2.5vw, 1rem);
  padding: clamp(0.5rem, 2.5vw, 1rem);
  display: flex;
  justify-content: center;
  flex-direction: column;
  border: 0.125rem solid transparent;
  border-radius: 0.25rem;
  transition: all ease 300ms;
  cursor: pointer;
}

a.next-post:hover,
a.previous-post:hover {
  border-color: black;
  box-shadow: 0 0 1rem black;
  translate: -0.25rem -0.25rem;
}

a.next-post {
  grid-column: next-s/next-e;
  align-items: right;
  text-align: right;
  padding-right: clamp(1rem, 2.5vw, 2rem);
}

a.previous-post {
  grid-column: previous-s/previous-e;
  align-items: left;
  text-align: left;
  padding-left: clamp(1rem, 2.5vw, 2rem);
}

a.next-post > span.title,
a.previous-post > span.title {
  white-space: nowrap;
  text-overflow: ellipsis;
  -o-text-overflow: ellipsis;
  -ms-text-overflow: ellipsis;
  overflow: hidden;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    -ms-text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
