footer {
  padding: 1rem;
  border-top: 0.0625rem solid black;
  background-color: #eee;
}

footer > section {
  display: flex;
  justify-content: center;
  column-gap: 1rem;
}

@keyframes patreon {
  0% {
    translate: 0 0;
  }
  10% {
    translate: 0 -0.5rem;
  }
  12.5% {
    translate: 0 0;
  }
  15% {
    translate: 0 -0.25rem;
  }
  16.5% {
    translate: 0 0;
  }
  18% {
    translate: 0 -0.12rem;
  }
  20% {
    translate: 0 0;
  }
}

footer > section > a > svg {
  font-size: clamp(1.2rem, 2.5vw, 1.6rem);
}

footer > section > a > img {
  height: clamp(1.2rem, 2.5vw, 1.6rem);
  border: none;
}

footer > section > a > svg.patreon {
  animation: patreon 3s linear infinite;
}

footer > span.copyright {
  text-align: center;
  margin-top: 0.625rem;
  display: block;
  font-size: clamp(0.5rem, 2.5vw, 0.7rem);
}
