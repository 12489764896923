article.blog-post-container {
  width: 100%;
  padding: 0 1rem;
  box-sizing: border-box;
  flex-grow: 1;
}

article.blog-post-container > h1 {
  font-size: clamp(1rem, 2.5vw, 1.6rem);
}

article.blog-post-container > section.blog-post-tags {
  margin-bottom: 1rem;
  display: flex;
  column-gap: 0.3125rem;
}

article.blog-post-container > span.blog-post-date {
  font-style: italic;
  font-size: clamp(0.6rem, 2.5vw, 0.8rem);
}

article.blog-post-container > section.blog-post-body {
  margin: 1rem 0;
  font-size: clamp(0.9rem, 2.5vw, 1.1rem);
  line-height: 1.5;
}

article.blog-post-container > section.blog-post-body > pre {
  font-size: clamp(0.8rem, 2.5vw, 1rem);
}

article.blog-post-container > section.blog-post-body > code.inline {
  display: inline;
  padding: 0 0.3125rem;
  box-sizing: border-box;
  background-color: #333;
  color: white;
  border-radius: 0.25rem;
  font-size: clamp(0.7rem, 2.5vw, 0.9rem);
}

article.blog-post-container > section.blog-post-body > img.reference,
article.blog-post-container > section.blog-post-body > video.reference {
  display: block;
  width: 100%;
  max-width: 750px;
  margin: 1rem auto;
  box-shadow: 0 0 0.25rem;
  border-radius: 0.25rem;
  border: 1px solid black;
  box-sizing: border-box;
}

article.blog-post-container > section.blog-post-body > img.reference.small {
  max-width: 500px;
}

.italic {
  font-style: italic;
}

article.blog-post-container > section.blog-post-contact {
  margin-bottom: 2rem;
  font-size: clamp(0.8rem, 2.5vw, 1rem);
}

article.blog-post-container
  > section.blog-post-body
  > div.previous-next-container {
  display: grid;
  align-items: center;
  grid-template-columns:
    [previous-s] calc(50% - 0.25rem) [previous-e next-s] calc(50% - 0.25rem)
    [next-e];
  margin: 1rem 0 0;
  column-gap: 0.5rem;
  box-sizing: border-box;
}
