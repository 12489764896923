article.blog-post-card {
  display: flex;
  transition: all ease 300ms;
  cursor: pointer;
  border-radius: 0.25rem;
  border: 0.3125rem transparent solid;
}

article.blog-post-card:hover {
  box-shadow: 0 0 1rem;
  border: 0.3125rem solid black;
  translate: -0.5rem -0.5rem;
}

article.blog-post-card > img {
  width: 25%;
  height: 25%;
  scale: 0.75;
  border-radius: 0.5rem;
}

@media screen and (orientation: portrait) {
  article.blog-post-card > img {
    scale: 1;
  }
}

article.blog-post-card > section.information {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 0.625rem;
  margin: 0 0 0 1rem;
}

article.blog-post-card > section.information > h2 {
  margin: 0;
  font-size: clamp(0.8rem, 2.5vw, 1.6rem);
}

article.blog-post-card > section.information > p {
  margin: 0;
  width: 90%;
  font-size: clamp(0.7rem, 2.5vw, 1rem);
  line-height: clamp(1rem, 2.5vw, 1.1rem);
}

article.blog-post-card > section.information > section {
  display: flex;
  column-gap: 0.3125rem;
}

article.blog-post-card > section.information > span {
  font-style: italic;
  font-size: clamp(0.6rem, 2.5vw, 0.9rem);
}

article.blog-post-card > section.information > span.decorated {
  text-decoration: underline;
  color: blue;
}
