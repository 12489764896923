h2.section-title {
  font-size: clamp(0.8rem, 2.5vw, 1.3rem);
  cursor: pointer;
  margin: 3rem 0 1rem;
}

h2.section-title > a {
  color: black;
}

h2.section-title > svg {
  color: grey;
  margin: 0 1rem;
  font-size: clamp(0.5rem, 2.5vw, 1rem);
  opacity: 0;
}

h2.section-title:hover > svg {
  opacity: 1;
}

h2.section-title > span {
  font-size: clamp(0.6rem, 2.5vw, 0.9rem);
  border: 0.0625rem solid black;
  border-radius: 0.25rem;
  padding: 0.25rem;
  opacity: 0;
}

h2.section-title > span.revealed {
  animation: revealed 1000ms;
}

@keyframes revealed {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
